<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >Rezervasyonlar</span
        >
        <span class="text-muted mt-3 font-weight-bold font-size-sm"
          >Müşterinin yapmış olduğu tüm rezervasyonlar</span
        >
      </h3>
    </div>
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0 mt-n3">
      <div class="table-responsive mt-5">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0 min-w-100px"></th>
              <th class="p-0 min-w-100px"></th>
              <th class="p-0 min-w-100px"></th>
            </tr>
          </thead>
          <tbody>
            <BookingItem
              v-for="booking in bookings"
              :key="booking.reservationNo"
              :reservation-id="booking.id"
              :product-name="booking.productName"
              :departure-date="booking.departureDate"
              :reservation-no="booking.reservationNo"
              :participants="booking.participants"
            ></BookingItem>
          </tbody>
        </table>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import BookingItem from "@/view/pages/reservation/components/details/BookingItem";
export default {
  props: {
    bookings: Array
  },
  components: {
    BookingItem
  }
};
</script>
