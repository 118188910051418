<template>
  <tr>
    <td class="pl-0">
      <router-link
        :to="{
          name: 'reservation-details',
          params: { id: reservationId, reservationNumber: reservationNo }
        }"
        v-slot="{ href }"
      >
        <a
          :href="href"
          class="
            text-dark
            font-weight-bolder
            text-hover-primary
            mb-1
            font-size-lg
          "
          >{{ productName }}</a
        >
      </router-link>
      <span class="text-muted font-weight-bold d-block"
        >ZNF-{{ reservationNo }}</span
      >
    </td>
    <td>
      <span class="text-dark-65 font-weight-bolder d-block">{{
        departureDateText
      }}</span>
      <span class="text-muted font-weight-bold d-block">{{
        participantsText
      }}</span>
    </td>
    <td class="text-right">
      <router-link
        :to="{
          name: 'reservation-details',
          params: { id: reservationId, reservationNumber: reservationNo },
        }"
        v-slot="{ href }"
      >
        <a :href="href" class="btn btn-icon btn-light btn-sm">
          <span class="svg-icon svg-icon-md svg-icon-danger">
            <inline-svg src="media/svg/icons/Navigation/Arrow-right.svg" />
          </span>
        </a>
      </router-link>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    reservationId: Number,
    productName: String,
    reservationNo: String,
    departureDate: String,
    participants: Array
  },
  computed: {
    ...mapGetters(["experienceConfig"]),

    participantsText() {
      let text = [];
      this.participants.forEach(el => {
        text.push(
          el.count + " " + this.experienceConfig(`participant.${el.id}.tr`)
        );
      });
      return text.join(", ");
    },

    departureDateText() {
      return moment(this.departureDate).format('D MMM, HH:mm');
    },
  },
  mounted() {
    moment.locale("tr");
  }
};
</script>

<style></style>
