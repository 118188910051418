<template>
  <div class="row" v-if="!isError">
    <div class="col-md-7 col-lg-12 col-xxl-7">
      <div>
        <ReservationDetails
          ref="reservation-details"
          :reservation-number="reservation.reservationNumber"
          :departure-date="reservation.departureDateTime"
          :participants="reservation.participants"
          :status="reservation.status"
          :reservation-date="reservation.reservationDate"
          :experience="reservation.experience"
          :price="reservation.price"
          :promotion-code="reservation.promotionCode"
          :reservation-id="id"
          :is-refunded="Boolean(reservation.isRefunded)"
          :pnr="reservation.pnr"
          @refunded="get"
        />
      </div>
      <div>
        <MeetingDetails :reservation-id="id" />
      </div>
      <div>
        <Participants
          :participants="participants"
          :reservation-number="reservationNumber"
          :bookings="reservation.customerBookings"
        ></Participants>
      </div>
      <div v-if="reservation.customerBookings.length">
        <Bookings :bookings="reservation.customerBookings"></Bookings>
      </div>
    </div>
    <div class="col-md-5 col-lg-12 col-xxl-5">
      <div>
        <CustomerDetails
          :name="customer.name"
          :surname="customer.surname"
          :country-code="customer.countryCode"
          :country="customer.country"
          :mobile="customer.mobile"
          :email="customer.email"
          :request-review="reservation.status && !review"
          :reservation-number="reservationNumber"
        />
      </div>
      <div v-if="review">
        <ReviewItem :review="review" :show-reviewer="false" />
      </div>
      <div>
        <Files :reservation-id="id" :reservation-number="reservationNumber" />
      </div>
      <div>
        <Cancellation :reservation-id="id" @cancel="reservationCancelled" />
      </div>
      <div>
        <div class="card card-custom bg-light-warning gutter-b">
          <div class="card-header border-0">
            <div class="card-title">
              <h3 class="card-label">
                <span class="d-block text-dark font-weight-bolder"
                  >Rezervasyon Notu</span
                >
              </h3>
            </div>
            <div class="card-toolbar">
              <button
                class="btn btn-dark btn-sm font-weight-bolder font-size-sm"
                @click="onModalShow"
              >
                {{ reservation.notes ? "Düzenle" : "Ekle" }}
              </button>
            </div>
          </div>
          <div class="card-body pt-2">
            <template v-if="!reservation.notes">
              <span class="text-muted font-size-lg">
                <em>Rezervasyon notu yok</em>
              </span>
            </template>
            <template v-else>
              <div
                class="text-dark font-weight-normal font-size-lg"
                v-html="reservation.notes"
              ></div>
            </template>
          </div>
        </div>
        <b-modal
          id="modal-notes"
          ref="modal-notes"
          no-fade
          centered
          scrollable
          header-class="py-3"
          footer-class="py-3"
          @ok="handleModalOk"
        >
          <template #modal-header="{ cancel }">
            <button
              type="button"
              class="btn btn-icon btn-hover-light btn-sm"
              @click="cancel"
            >
              <i class="ki ki-close icon-nm text-dark"></i>
            </button>
            <div
              class="
                d-flex
                flex-fill
                align-items-center
                justify-content-center
                pt-2
              "
            >
              <div
                class="d-flex flex-column align-items-center"
                style="margin-left: -32px"
              >
                <h5>Rezervasyon notu</h5>
              </div>
            </div>
          </template>

          <div class="max-h-300px">
            <div class="alert alert-danger" role="alert" v-if="modal.error">
              <div class="alert-text">
                Bir şeyler ters gitti! Lütfen tekrar deneyin.
              </div>
            </div>
            <ckeditor
              :editor="editor"
              v-model="modal.notes"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <template #modal-footer="{ ok, cancel }">
            <div
              class="
                d-flex
                flex-row
                align-items-center
                justify-content-between
                flex-fill
              "
            >
              <button
                type="button"
                class="btn btn-hover-transparent-dark font-weight-bolder py-4"
                @click="cancel"
              >
                İptal et
              </button>

              <button
                type="button"
                class="btn btn-dark font-weight-bolder px-5 py-4"
                @click="ok"
              >
                Kaydet
              </button>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import ReservationDetails from "@/view/pages/reservation/components/details/ReservationDetails";
import CustomerDetails from "@/view/pages/reservation/components/details/Customer";
import Files from "@/view/pages/reservation/components/details/Files";
import Cancellation from "@/view/pages/reservation/components/details/Cancellation";
import MeetingDetails from "@/view/pages/reservation/components/details/Meeting";
import Bookings from "@/view/pages/reservation/components/details/Bookings";
import ReviewItem from "@/view/pages/experience/components/review/ReviewItem";
import Participants from "@/view/pages/reservation/components/details/Participants";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: {
    ReservationDetails,
    CustomerDetails,
    Files,
    Cancellation,
    MeetingDetails,
    Bookings,
    ReviewItem,
    Participants,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      id: this.$route.params.id,
      reservationNumber: this.$route.params.reservationNumber,
      isLoading: false,
      isError: false,
      customer: {},
      reservation: {},
      participants: [],
      review: "",
      modal: {
        notes: "",
        error: false,
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Önemli bilgileri anlatın",
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
        ],
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
          ],
        },
      },
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.isLoading = true;
      ApiService.get(`reservation/get.req.php?id=${this.id}`)
        .then(({ data }) => {
          this.reservation = data.data.reservation;
          this.meeting = data.data.meeting;
          this.customer = data.data.customer;
          this.reservation.customerBookings = data.data.otherReservations;
          this.review = data.data.review;
          this.participants = data.data.participants;
          this.isError = false;
        })
        .catch(({ response }) => {
          this.isError = true;
          alert(response.data.errorMessage);
        });
    },
    reservationCancelled() {
      this.$refs["reservation-details"].get();
    },
    onModalShow() {
      this.modal.error = false;
      this.modal.notes = this.reservation.notes;
      this.$bvModal.show("modal-notes");
    },
    handleModalOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      ApiService.post("reservation/notes.req.php", {
        id: this.id,
        notes: this.modal.notes,
      })
        .then(() => {
          this.modal.error = false;
          this.reservation.notes = this.modal.notes;
          this.$nextTick(() => {
            this.$bvModal.hide("modal-notes");
          });
        })
        .catch(() => {
          this.modal.error = true;
        });
    },
  },
};
</script>

<style></style>
