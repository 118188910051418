var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"pl-0"},[_c('router-link',{attrs:{"to":{
        name: 'reservation-details',
        params: { id: _vm.reservationId, reservationNumber: _vm.reservationNo }
      }},scopedSlots:_vm._u([{key:"default",fn:function({ href }){return [_c('a',{staticClass:"text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg",attrs:{"href":href}},[_vm._v(_vm._s(_vm.productName))])]}}])}),_c('span',{staticClass:"text-muted font-weight-bold d-block"},[_vm._v("ZNF-"+_vm._s(_vm.reservationNo))])],1),_c('td',[_c('span',{staticClass:"text-dark-65 font-weight-bolder d-block"},[_vm._v(_vm._s(_vm.departureDateText))]),_c('span',{staticClass:"text-muted font-weight-bold d-block"},[_vm._v(_vm._s(_vm.participantsText))])]),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
        name: 'reservation-details',
        params: { id: _vm.reservationId, reservationNumber: _vm.reservationNo },
      }},scopedSlots:_vm._u([{key:"default",fn:function({ href }){return [_c('a',{staticClass:"btn btn-icon btn-light btn-sm",attrs:{"href":href}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-danger"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Arrow-right.svg"}})],1)])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }