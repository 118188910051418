<template>
  <div>
    <div class="card card-custom gutter-b" :class="[{'bg-diagonal': cancellation.status === 0}, {'bg-diagonal': cancellation.status === 1}]">
      <div class="card-body">
        <div class="min-h-150px d-flex align-items-center justify-content-center" v-if="isLoading">
          <button type="button" class="btn btn-light spinner spinner-darker-dark spinner-left mr-3">
            Yükleniyor
          </button>
        </div>
        <div v-else>
          <div class="d-flex flex-column align-items-center justify-content-center" v-if="error.status">
            <img src="media/svg/illustrations/failure.svg" style="width:40%"/>
            <label class="d-block font-weight-bold font-size-h6 text-dark-50 text-center py-5 px-20">{{ error.message }}</label> 
          </div>
          <div v-else>
            <div class="d-flex align-items-center justify-content-between p-4 flex-lg-wrap flex-xl-nowrap" v-if="cancellation.status === 1">
              <div class="d-flex flex-column mr-5">
                <h4 class="h4 text-dark mb-5">
                  {{ title }}
                </h4>
                <p class="text-dark-50">
                  {{ description }}
                </p>
              </div>
              <div class="ml-6 ml-lg-0 ml-xxl-6 flex-shrink-0">
                <button type="button" class="btn font-weight-bolder btn-danger py-4 px-6" @click="$bvModal.show('modal-cancel-reservation')">
                    İptal Et
                </button>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-between p-4 flex-lg-wrap flex-xl-nowrap" v-if="cancellation.status === 0">
              <div class="d-flex flex-column mr-5">
                <h4 class="h4 text-danger mb-5">
                    {{ title }}
                </h4>
                <p class="text-dark-50">
                  {{ description }}
                </p>
              </div>
              <div class="ml-6 ml-lg-0 ml-xxl-6 flex-shrink-0">
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

    <!--Begin: CANCELLATION MODAL-->
    <b-modal id="modal-cancel-reservation" ref="modal-cancel-reservation" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleModalCancel" @hide="handleModalCancel" @ok="handleModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>İptal notu girin</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">İptal notu</label>
        <textarea
          class="form-control mb-2"
          rows="4"
          placeholder="Açıklamayı buraya yazın"
          :maxlength="modal.maxLength"
          v-model="modal.cancellationNote"
        ></textarea>
        <span class="text-muted pl-1">
          {{ modal.cancellationNote.length }} / {{ modal.maxLength }}
        </span>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >Vazgeç</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="modal.cancellationNote.length === 0"
            @click="ok"
          >
            Rezervasyonu iptal et
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: CANCELLATION MODAL-->
  </div>
  
</template>

<script>
import moment from 'moment';
import ApiService from '@/core/services/api.service';

export default {
  props: ["reservationId"],
  data() {
    return {
      cancellation: {},
      modal: {
        maxLength: 400,
        cancellationNote: ''
      },
      error: {
        status: false,
        message: ''
      },
      isLoading: true
    }
  },
  computed: {
    title() {
      if (this.cancellation.status === 1) {
        return 'Rezervasyonu İptal Et'
      } else {
        return 'Rezervasyon İptal Edildi'
      }
    },
    description() {
      if (this.cancellation.status === 1) {
        if (this.cancellation.freeCancel === 1) {
          return moment(this.cancellation.freeCancelDate).format('D MMMM YYYY, HH:mm') + ' tarihine kadar ücretsiz iptal hakkı bulunmakta.';
        } else {
          return 'Bu rezervasyon için ücretsiz iptal hakkı bulunmamakta.';
        }
      } else {
        return 'Bu rezervasyon ' + moment(this.cancellation.cancellationDate).format('D MMMM YYYY, HH:mm') + ' tarihinde iptal edildi. "' + this.cancellation.cancellationNote + '"';
      }
    }
  },
  mounted() {
    moment.locale('tr');
    this.get();
  },
  methods: {
    handleModalCancel() {
      this.modal.cancellationNote = '';
    },
    handleModalOk() {
      const postData = {
        id: this.reservationId,
        note: this.modal.cancellationNote
      }
      ApiService.post('reservation/cancellation/cancel.req.php', postData)
      .then(() => {
        this.get();
        this.$emit('cancel');
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },
    get() {
      this.isLoading = true;
      ApiService.get(`reservation/cancellation/get.req.php?id=${this.reservationId}`)
      .then(({ data }) => {
        this.cancellation = data.cancellation;
        this.error.status = false;
        setTimeout(() => {
          this.isLoading = false;
        }, 500);

      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      });
    }
  }
}
</script>

<style>

</style>