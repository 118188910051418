<template>
  <div class="card card-custom card-fit gutter-b">
    <div class="card-header border-0">
      <div class="card-title">
        <h3 class="card-label">
          <span
            class="
              d-inline-flex
              align-items-center
              text-dark
              font-weight-bolder
            "
          >
            <img
              alt=""
              class="max-w-20px pb-1 mr-3"
              src="/media/svg/files/pdf.svg"
            />
            Dokümanlar
          </span>
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-dark btn-sm font-weight-bolder font-size-sm"
          @click="createPDF"
          :disabled="isCreatingPdf"
        >
          Konaklama Talep Formu
        </button>
      </div>
    </div>
    <div class="card-body">
      <div>
        <a
          :href="`https://media.zenofontours.com/voucher/${reservationId}TR${reservationNumber}.pdf`"
          target="_blank"
          class="
            d-flex
            align-items-center
            text-dark
            font-weight-bold font-size-lg
            text-hover-primary
            py-1
          "
        >
          <i class="flaticon2-clip-symbol text-warning icon-1x mr-2"></i
          >Voucher.pdf
        </a>
        <a
          :href="`https://media.zenofontours.com/invoice/${reservationNumber}.pdf`"
          target="_blank"
          class="
            d-flex
            align-items-center
            text-dark
            font-weight-bold font-size-lg
            text-hover-primary
            py-1
          "
        >
          <i class="flaticon2-clip-symbol text-warning icon-1x mr-2"></i>Hesap
          Ekstresi.pdf
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  props: ["reservationId", "reservationNumber"],
  data() {
    return {
      isCreatingPdf: false,
    };
  },
  methods: {
    createPDF() {
      this.isCreatingPdf = true;
      ApiService.get(
        `reservation/create-hotel-reservation-file.req.php?id=${this.reservationId}`
      )
        .then(({ data }) => {
          this.isCreatingPdf = false;
          window.open(data.url, "_blank").focus();
        })
        .catch(({ response }) => {
          this.isCreatingPdf = false;
          alert(response.data.errorMessage);
        });
    },
  },
};
</script>

<style></style>
