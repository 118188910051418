<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <div class="row">
        <div class="col-6 md-8">
          <div class="mb-8 d-flex flex-column">
            <span class="text-muted font-weight-bold mb-4"
              >Buluşma Noktası</span
            >
            <span class="text-dark font-weight-bolder font-size-lg">{{
              pickUp.name.tr
            }}</span>
            <span class="text-dark font-weight-normal font-size-lg">{{
              pickUp.detail.tr
            }}</span>
          </div>
          <div class="mb-8 d-flex flex-column" v-if="dropOff.length !== 0">
            <span class="text-muted font-weight-bold mb-4">Varış Noktası</span>
            <span class="text-dark font-weight-bolder font-size-lg">{{
              dropOff.name.tr
            }}</span>
            <span class="text-dark font-weight-normal font-size-lg">{{
              dropOff.detail.tr
            }}</span>
          </div>
        </div>
        <div class="col-6 md-4">
          <gmap-map
            ref="meetingMap"
            class="rounded-map"
            :options="map.options"
            :center="map.center"
            :zoom="map.zoom"
            style="width: 100%; height: 200px"
          >
            <gmap-marker
              :position="pickUpPosition"
              :clickable="false"
              :draggable="false"
              :animation="google.maps.Animation.DROP"
            />

            <gmap-marker
              v-if="dropOff.length !== 0"
              :position="dropOffPosition"
              :clickable="false"
              :draggable="false"
              :animation="google.maps.Animation.DROP"
            />
          </gmap-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import * as VueGoogleMaps from 'vue2-google-maps';

export default {
  props: ["reservationId"],
  data() {
    return {
      map: {
        zoom: 15,
        center: { lat: 41.0055066, lng: 39.731024 },
        options: {
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          disableDefaultUi: false
        }
      },
      error: {
        status: false,
        message: ''
      },
      mapLoaded: false,
      isLoading: false,
      pickUp: null,
      dropOff: null
    }
  },
  computed: {
    pickUpPosition() {
      return {lat: parseFloat(this.pickUp.position.lat), lng: parseFloat(this.pickUp.position.lng)};
    },
    dropOffPosition() {
      return {lat: parseFloat(this.dropOff.position.lat), lng: parseFloat(this.dropOff.position.lng)};
    },
    google: VueGoogleMaps.gmapApi
  },
   mounted() {
    this.get();
    const self = this;
    this.$gmapApiPromiseLazy().then(() => { 
      self.mapLoaded = true;
      self.updateBounds();
    });
  },
  methods: {
    get() {
      this.isLoading = true;
      ApiService.get(`reservation/meeting/get.req.php?id=${this.reservationId}`)
      .then(({ data }) => {
        this.pickUp = data.meeting.pickup;
        this.dropOff = data.meeting.dropoff;
        this.updateBounds();
        this.error.status = false;
        setTimeout(() => {
          this.isLoading = false;
        }, 500);

      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      });
    },
    updateBounds() {
      const self = this;
      this.$refs.meetingMap.$mapPromise.then((map) => {
        var bounds = new this.google.maps.LatLngBounds();
        bounds.extend(self.pickUpPosition);
        if (self.dropOff.length !== 0) {
          bounds.extend(self.dropOffPosition);
        }
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      });
    },
  }
}
</script>

<style>
.vue-map-container.rounded-map .vue-map {
  border-radius: 0.85rem !important;
  border: 1px solid var(--secondary);
}
</style>