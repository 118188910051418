<template>
  <div class="card card-custom gutter-b">
    <div class="card-header border-0">
      <div class="card-title">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder">Katılımcılar</span>
        </h3>
      </div>
      <div v-if="bookings.length && participants.length" class="card-toolbar">
        <button
          class="btn btn-dark btn-sm font-weight-bolder font-size-sm"
          @click="$bvModal.show('modal-copy')"
        >
          Bilgileri kopyala
        </button>
      </div>
    </div>
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0 mt-n3">
      <div class="table-responsive">
        <table
          class="table table-borderless table-vertical-center"
          v-if="participants.length"
        >
          <tbody>
            <tr
              v-for="(participant, index) in participants"
              :key="participant.id"
            >
              <td class="pl-0">
                <span class="text-dark font-weight-bolder"
                  >{{ index + 1 }}.</span
                >
              </td>
              <td class="pl-0">
                <span class="text-dark font-weight-bolder">{{
                  `${participant.name} ${participant.surname}`
                }}</span>
              </td>
              <td>
                <span class="text-default font-weight-bold d-block">
                  <template v-if="participant.gender === 'M'">Erkek</template>
                  <template v-if="participant.gender === 'F'">Kadın</template>
                </span>
              </td>
              <td>
                <span class="text-default font-weight-bold d-block">{{
                  participant.country
                }}</span>
              </td>
              <td>
                <span class="text-default font-weight-bold d-block">{{
                  participant.passportNumber
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="!participants.length">
          <div class="text-center mt-4 my-2">
            <span class="text-default font-weight-bold font-size-lg">
              Katılımcı bilgisi için aşağıdaki linki müşteriyle
              paylaşabilirsiniz.
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <span class="text-dark font-weight-bolder font-size-lg mr-4">{{
              participantInfoURLNormalized
            }}</span>
            <button type="button" style="height: 30px;">
              <i
                v-if="!copiedMessage"
                @click="doCopy"
                class="ki ki-copy icon-lg"
              ></i>
              <span v-else class="font-size-lg">{{ copiedMessage }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--end::Body-->
    <b-modal
      id="modal-copy"
      ref="modal-copy"
      no-fade
      centered
      scrollable
      header-class="py-3"
      footer-class="py-3"
      @cancel="handleModalCancel"
      @hide="handleModalCancel"
      @ok="handleModalOk"
    >
      <template #modal-header="{ cancel }">
        <button
          type="button"
          class="btn btn-icon btn-hover-light btn-sm"
          @click="cancel"
        >
          <i class="ki ki-close icon-nm text-dark"></i>
        </button>
        <div
          class="d-flex flex-fill align-items-center justify-content-center pt-2"
        >
          <div
            class="d-flex flex-column align-items-center"
            style="margin-left: -32px;"
          >
            <h5>Katılımcı bilgilerini kopyala</h5>
          </div>
        </div>
      </template>

      <div class="max-h-300px">
        <div v-for="(item, index) in bookings" :key="index" class="form-group">
          <div
            class="option selectable mb-3"
            :class="{ selected: modal.itemId === item.pnr }"
            @click="selectItem(item.pnr, item.reservationNo)"
          >
            <span class="option-label">
              <span class="option-head">
                <div class="option-title">
                  {{ item.productName }}
                </div>
              </span>
              <span class="option-body text-dark-50 mw-80 pt-2">
                {{ getHumanizeDate(item.departureDate) }}
              </span>
            </span>
          </div>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          class="d-flex flex-row align-items-center justify-content-between flex-fill"
        >
          <button
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="cancel"
          >
            İptal et
          </button>

          <button
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            :disabled="!modal.itemId"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>
    </b-modal>
    <Notification
      v-if="notification.status"
      :state="notification.state"
      :message="notification.message"
    />
  </div>
</template>

<script>
import moment from "moment";
import ApiService from "@/core/services/api.service";
import Notification from "@/view/content/notification/Notification";
export default {
  props: {
    participants: Array,
    reservationNumber: {
      type: String
    },
    bookings: Array
  },
  components: {
    Notification
  },
  computed: {
    participantInfoURL() {
      return `https://www.zenofontours.com/participants?reservation=${this.reservationNumber}`;
    },
    participantInfoURLNormalized() {
      return `zenofontours.com/${this.reservationNumber}`;
    }
  },
  data() {
    return {
      modal: {
        reservationNumber: undefined,
        itemId: undefined
      },
      copiedMessage: "",
      notification: {
        status: false,
        state: "",
        message: ""
      }
    };
  },
  methods: {
    doCopy: function() {
      this.$copyText(this.participantInfoURL).then(() => {
        this.copiedMessage = "Kopyalandı!";
        setTimeout(() => {
          this.copiedMessage = "";
        }, 3000);
      });
    },
    getHumanizeDate(date) {
      return moment(date).format("D MMMM YYYY, HH:mm");
    },
    selectItem(pnr, reservationNumber) {
      this.modal.itemId = pnr;
      this.modal.reservationNumber = reservationNumber;
    },
    handleModalCancel() {
      this.modal.itemId = undefined;
    },
    handleModalOk() {
      const postData = {
        fromReservationNumber: this.reservationNumber,
        toReservationNumber: this.modal.reservationNumber,
        pnr: this.modal.itemId
      };
      ApiService.post("reservation/participants/copy.req.php", postData)
        .then(() => {
          this.notify("success", "Yolcu bilgileri başarıyla kopyalandı!");
        })
        .catch(({ response }) => {
          this.notify("danger", response.data.errorMessage);
        });
    },
    notify(state, message) {
      this.notification.state = state;
      this.notification.message = message;
      this.notification.status = true;
      this.notification.timeout = setTimeout(() => {
        this.notification.status = false;
      }, 4000);
    }
  }
};
</script>
<style>
.option {
  cursor: pointer;
  border-width: 2px;
}
.option .option-label .option-body {
  font-size: 1.08rem !important;
  max-width: 80% !important;
}
.option.selectable.selected,
.option.selectable:hover {
  border-color: var(--dark);
  background-color: var(--light);
}
.option.selected {
  border-width: 2px;
}
</style>
