<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-body p-15">
      <div>
        <div>
          <div
            class="row"
            :class="[{ 'mb-17': status === 1 }, { 'mb-10': status === 0 }]"
          >
            <div class="col-xxl-5 mb-11 mb-xxl-0">
              <!--begin::Image-->
              <div class="card card-custom">
                <div
                  class="
                    bgi-no-repeat bgi-position-center bgi-size-cover
                    rounded
                    min-h-150px
                    w-100
                  "
                  :style="{
                    backgroundImage: `url(${experience.image})`,
                  }"
                ></div>
              </div>
              <!--end::Image-->
            </div>
            <div class="col-xxl-7 pl-xxl-11">
              <router-link
                :to="{ name: 'show-experience', params: { id: experience.id } }"
                v-slot="{ href }"
              >
                <a :href="href">
                  <h2
                    class="
                      font-size-h2
                      text-hover-primary
                      font-weight-bolder
                      text-dark
                      mb-4
                    "
                  >
                    {{ experience.title }}
                  </h2>
                </a>
              </router-link>
              <div class="font-size-h2 mb-2 text-dark-50">
                <span
                  class="
                    d-block
                    font-size-h6
                    text-muted
                    mb-0
                    font-weight-bold
                    line-height-sm
                  "
                  style="text-decoration: line-through"
                  v-if="promotionCode !== null"
                  >{{ price.original }} {{ price.currency }}</span
                >
                <div class="d-flex align-items-center">
                  <span
                    class="font-weight-bolder"
                    :class="[
                      { 'text-success': status === 1 },
                      { 'text-danger': status === 0 },
                    ]"
                    >{{ price.sale }}
                    <span class="font-weight-bold">{{
                      price.currency
                    }}</span></span
                  >
                  <span
                    class="
                      label label-info label-inline
                      font-weight-bolder
                      ml-3
                    "
                    v-if="promotionCode !== null"
                    >{{ promotionCode }}</span
                  >
                </div>
              </div>
              <div>
                <span class="text-muted font-weight-bolder font-size-h6">{{
                  participantsText
                }}</span>
              </div>
            </div>
          </div>
          <div class="form-group" v-if="false">
            <div class="alert alert-danger bg-light-danger" role="alert">
              <span class="svg-icon svg-icon-lg pr-2 svg-icon-danger">
                <inline-svg src="media/svg/icons/Code/Error-circle.svg" />
              </span>
              <span class="text-body">Rezervasyon iptal edildi.</span>
            </div>
          </div>
          <div class="form-group" v-if="status === 0 && !isRefunded">
            <div
              class="
                alert alert-warning
                bg-light-warning
                d-flex
                align-items-center
                justify-content-between
              "
              role="alert"
            >
              <div>
                <span class="svg-icon svg-icon-lg pr-2 svg-icon-warning">
                  <inline-svg src="media/svg/icons/Code/Error-circle.svg" />
                </span>
                <span class="text-body"
                  >Lütfen
                  <strong>{{ price.sale }} {{ price.currency }}</strong>
                  tutarındaki ödemeyi iade edin.
                </span>
              </div>
              <button
                type="button"
                class="btn btn-sm font-weight-bolder btn-warning px-6"
                @click="$bvModal.show('modal-mark-as-refunded')"
              >
                İade edildi olarak işaretle
              </button>
            </div>
          </div>
          <div class="row mb-6">
            <!--begin::Info-->
            <div class="col-6 col-md-4">
              <div class="mb-8 d-flex flex-column">
                <span class="text-muted font-weight-bold mb-4"
                  >Rezervasyon No</span
                >
                <span class="text-dark font-weight-bolder font-size-lg"
                  >ZNF-{{ reservationNumber }}</span
                >
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="mb-8 d-flex flex-column">
                <span class="text-muted font-weight-bold mb-4"
                  >Hareket Tarihi</span
                >
                <router-link
                  :to="{ name: 'operation-details', params: { pnr: pnr } }"
                  v-slot="{ href }"
                >
                  <a :href="href">
                    <span
                      class="
                        text-dark text-hover-primary
                        font-weight-bolder font-size-lg
                      "
                      >{{ departureDateText }}</span
                    >
                  </a>
                </router-link>
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="mb-8 d-flex flex-column">
                <span class="text-muted font-weight-bold mb-4"
                  >Katılımcılar</span
                >
                <span class="text-dark font-weight-bolder font-size-lg">{{
                  participantsText
                }}</span>
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="d-flex flex-column">
                <span class="text-muted font-weight-bold mb-4">Durum</span>
                <span class="text-dark font-weight-bolder font-size-lg">
                  <div v-if="status === 1">
                    <span class="svg-icon svg-icon-lg pr-2 svg-icon-success">
                      <inline-svg src="media/svg/icons/Code/Done-circle.svg" />
                    </span>
                    Onaylandı
                  </div>
                  <div v-if="status === 0">
                    <span class="svg-icon svg-icon-lg pr-2 svg-icon-danger">
                      <inline-svg src="media/svg/icons/Code/Error-circle.svg" />
                    </span>
                    İptal Edildi
                  </div>
                </span>
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="d-flex flex-column">
                <span class="text-muted font-weight-bold mb-4"
                  >Satın Alma Tarihi</span
                >
                <span class="text-dark font-weight-bolder font-size-lg">{{
                  reservationDateText
                }}</span>
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="d-flex flex-column">
                <span class="text-muted font-weight-bold mb-4">Taksit</span>
                <span class="text-dark font-weight-bolder font-size-lg">{{
                  installmentText
                }}</span>
              </div>
            </div>
            <!--end::Info-->
          </div>
        </div>
      </div>
    </div>
    <!--Begin: CANCELLATION MODAL-->
    <b-modal
      id="modal-mark-as-refunded"
      ref="modal-mark-as-refunded"
      no-fade
      centered
      header-class="py-3"
      footer-class="py-3"
      @ok="handleModalOk"
    >
      <template #modal-header="{ cancel }">
        <button
          type="button"
          @click="cancel"
          class="btn btn-icon btn-hover-light btn-sm"
        >
          <i class="ki ki-close icon-nm text-dark"></i>
        </button>
        <div
          class="
            d-flex
            flex-fill
            align-items-center
            justify-content-center
            pt-2
          "
        >
          <div
            class="d-flex flex-column align-items-center"
            style="margin-left: -32px"
          >
            <h5>Emin misiniz?</h5>
          </div>
        </div>
      </template>

      <div class="form-group">
        <p class="font-size-lg">
          Lütfen ödeme kanalı üzerinden iadeyi gerçekleştirmiş olduğunuzdan emin
          olun. Bu işlem geri alınamaz.
        </p>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            flex-fill
          "
        >
          <button
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="cancel"
          >
            Vazgeç
          </button>

          <button
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            @click="ok"
          >
            Evet, iade edildi olarak işaretle
          </button>
        </div>
      </template>
    </b-modal>
    <!--End: CANCELLATION MODAL-->
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
  props: {
    reservationId: Number,
    reservationNumber: {
      type: String,
      default: "",
      required: true,
    },
    departureDate: {
      type: String,
      default: "-",
    },
    participants: Array,
    status: Number,
    reservationDate: String,
    installment: {
      type: Number,
      default: 1,
    },
    experience: Object,
    price: Object,
    promotionCode: {
      type: String,
      default: null,
    },
    isRefunded: {
      type: Boolean,
      required: true,
    },
    pnr: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["experienceConfig"]),

    reservationDateText() {
      return moment(this.reservationDate).format("D MMMM YYYY, HH:mm");
    },
    departureDateText() {
      return moment(this.departureDate).format("D MMMM YYYY, HH:mm");
    },
    participantsText() {
      let text = [];
      this.participants.forEach((el) => {
        text.push(
          el.count + " " + this.experienceConfig(`participant.${el.id}.tr`)
        );
      });
      return text.join(", ");
    },
    installmentText() {
      if (this.installment === 1) {
        return "Tek Çekim";
      }
      return `${this.installment} Taksit`;
    },
  },
  mounted() {
    moment.locale("tr");
  },
  methods: {
    handleModalOk() {
      ApiService.get(
        `reservation/cancellation/mark-as-refunded.req.php?id=${this.reservationId}`
      )
        .then(() => {
          this.$emit("refunded");
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
  },
};
</script>

<style></style>
